




























import { getCouponCategoryList, getCouponProList, ICategoryInfo } from '@/api/product';
import BaseList from '@/components/Base/BaseList/index.vue';
import { Vue, Component, Ref } from 'vue-property-decorator';
import ProductCard from '@/components/Base/ProductCard/index.vue';
@Component({
  name: '',
  components: { BaseList, ProductCard }
})
export default class extends Vue {
  show = false;

  activeKey = 0;
  activeCatItemKey = -1;

  @Ref('myList')
  myList!: BaseList;

  get param() {
    if (this.activeCatItemKey !== -1) {
      return {
        catId: this.options[this.activeKey].children[this.activeCatItemKey].catId
      };
    }
    return {};
  }

  get api() {
    return getCouponProList(this.id);
  }

  get id() {
    return this.$route.params.id;
  }

  options: ICategoryInfo[] = [];

  handleClickCatItem(index: number) {
    this.activeCatItemKey = index;
    this.show = false;
    this.myList.reload();
  }

  async getCouponCategoryList() {
    const { payload } = await getCouponCategoryList(this.id);
    this.options = payload;
  }
  created() {
    this.getCouponCategoryList();
  }
}
